<script lang="ts">
	import {
		PUBLIC_B2B_FUNNEL_URL,
		PUBLIC_GOOGLE_TAG_MANAGER_ID,
		PUBLIC_UTM_CAMPAIGN,
		PUBLIC_UTM_MEDIUM,
	} from '$env/static/public'
	import { set_modal_state, get_modal_state } from '$lib/components/context'
	import { CookieBanner, Dialog, Footer } from '$lib/components'
	import { GTM } from '$lib/analytics'

	set_modal_state()
	let is_modal_open = get_modal_state()
	let w: number, h: number

	import { page } from '$app/stores'

	// Create utm_source as encoded string
	$: utm_content = encodeURIComponent($page.url.href)
	$: utm_source = encodeURIComponent($page.url.host)
</script>

<slot />

{#if PUBLIC_GOOGLE_TAG_MANAGER_ID}
	<GTM />
{/if}

<CookieBanner />

<Dialog bind:is_open={$is_modal_open}>
	<button
		on:click={() => ($is_modal_open = false)}
		slot="close-button"
		class="absolute right-2 top-2 z-10 grid h-14 w-14 place-content-center rounded bg-gray-200 bg-opacity-50 p-4 text-3xl text-gray-500 hover:bg-opacity-100 hover:text-gray-900 focus-visible:outline-none sm:focus-visible:outline sm:focus-visible:outline-2 sm:focus-visible:outline-offset-2 sm:focus-visible:outline-gray-900"
	>
		&times;
	</button>
	<svelte:fragment slot="title" />
	<svelte:fragment slot="description" />
	<svelte:fragment slot="content">
		<div
			class="h-[calc(100vh-10rem)] min-h-[47rem] sm:h-[calc(100vh-7rem)]"
			bind:offsetWidth={w}
			bind:offsetHeight={h}
		>
			<iframe
				src="{PUBLIC_B2B_FUNNEL_URL}?utm_source={utm_source}&utm_content={utm_content}&utm_medium={PUBLIC_UTM_MEDIUM}&utm_campaign={PUBLIC_UTM_CAMPAIGN}"
				title="funnel"
				width={w}
				height={h}
				loading="lazy"
			/>
			&nbsp;
		</div>
	</svelte:fragment>
	<svelte:fragment slot="buttons" />
</Dialog>
